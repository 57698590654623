<template lang="pug">
.scene
  .box
    .columns.is-vcentered
      .column
        img(v-if="payload.logo_url" :src="payload.logo_url")
        .title.is-3 {{payload.title}}
        .subtitle.is-4 {{payload.subtitle}}
      .column
        div(v-if="payload.qr")
          a(:href="show_url")
            img(:src="qr_url")
    .columns
      .column
        .block(v-if="players") Players: {{players}}
        .block
          button.button.is-primary.is-fullwidth.is-outlined.is-large(@click="begin()") Tap to Start
  
</template>

<script>
import Scene from '@/scene';

export default {
  name: 'Title',
  extends: Scene,

  props: {
  },

  data: ()=>({
  }),
  
  components: {
  },

  mounted() {
  },

  methods: {
    begin(val) {
      this.publish({
        type: "BEGIN"
      });
    },
  },

  computed: {
    show_url() {
      return window.location.origin+this.payload.show_path;
    },

    qr_url() {
      return `https://chart.googleapis.com/chart?cht=qr&chs=512&chl=${encodeURIComponent(this.show_url)}`
    },

    players() {
      if (this.$store && this.$store.state.show.uid_to_player_name)
        return Object.values(this.$store.state.show.uid_to_player_name).join(", ");
      else
        return "none";
    }
  },
} 
</script>

<style lang="scss" scoped>
.scene {
  text-align: center;
}
  
</style>
