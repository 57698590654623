<template lang="pug">
.scene
  .box.has-text-centered
    div.is-size-3 Your Answer
    div.is-size-2 {{payload.answer}}
    div.is-size-3 is
    button.button.is-size-3.has-text-white.is-uppercase(:class='[payload.good ? "correct" : "incorrect"]') {{payload.good ? "correct" : "incorrect"}}
    
</template>

<script>
import Scene from '@/scene';

export default {
  name: 'Correct',
  extends: Scene,

  data: ()=>({
  }),
  
  components: {
  },

  mounted() {
  },

  methods: {
  },

  computed: {
  },
} 
</script>

<style lang="scss" scoped>
.correct {
  background: green;
}
.incorrect {
  background: red;
}

</style>

