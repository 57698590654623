
<template lang="pug">
.scene.p-2
  .box(ref="stream_element")

</template>

<script>
import Scene from '@/scene';

export default {
  name: 'Live',
  extends: Scene,

  props: {
    payload: Object,
  },

  data: ()=>({
  }),
  
  components: {
  },

  mounted() {
    this.$store.commit('set_stream_element', this.$refs.stream_element);
  },

  methods: {
  },

  computed: {
  },
} 
</script>
