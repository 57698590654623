<template lang="pug">
.scene
  .box.has-text-centered
    .block
      .title.is-6 {{payload.title}}
    .block {{payload.question}}
    button.button.is-fullwidth.is-outlined.is-inverted.answer-button(
      v-for="answer in payload.answers"
      :disabled="answer.length == 0"
      :class="[ answer == this.my_answer ? 'is-success' : 'is-primary' ]"
      @click="onclick"
    ) {{answer}}
</template>

<script>
import Scene from '@/scene';

export default {
  name: 'MultipleChoice',
  extends: Scene,

  data: ()=>({
    my_answer: null
  }),
  
  components: {
  },

  mounted() {
    this.$el.querySelectorAll("button").forEach(el=>{
      el.classList.add("no-click");
      setTimeout(()=>{
        el.classList.remove("no-click");
      }, 200);
    });
  },

  methods: {
    onclick(el) {
      let val = el.target.innerText;
      if (val.length) {
        this.my_answer = val;
        this.set_response({
          answer: this.my_answer,
          loaded_for: this.loaded_for
        });
      }
    },
  },

  computed: {
  },
} 
</script>

<style lang="scss" scoped>
  
.scene {
  padding: 30px;
}

.answer-button {
  margin-top: 10px;
}

.no-click {
  pointer-events: none;
}


</style>
