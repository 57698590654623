<template lang="pug">
span
  a(@click="_open()")
    .icon: i.fas.fa-qrcode
  Teleport(to="body")
    .modal(:class="{'is-active': is_active}")
      .modal-background(@click="_close")
      .modal-content
        img(:src="qr_url")

</template>

<script>

export default {
  name: 'ClickableQRModal',

  props: {
    url: String,
    close: Function
  },

  data: ()=>({
    is_active: false
  }),

  components: {
  },
  
  async created() {
  },

  methods: {
    _open() {
      this.is_active = true;
    },

    _close() {
      this.is_active = false;
      if (this.close)
        this.close();
    }
  },

  computed: {
    qr_url() {
      return `https://chart.googleapis.com/chart?cht=qr&chs=512&chl=${encodeURIComponent(this.url)}`
    },
  },
} 
</script>

<style scoped lang="scss">
  img {
  }
  .modal {
    width: 100vw;
    height: 100vh;
  }
</style>

