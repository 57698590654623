<template lang="pug">
.frame.is-flex.is-flex-direction-column
  video#background_video(playsinline autoplay muted loop)
    source(:src="options.background_video.url" type="video/mp4")
  .show_url.is-flex-shrink-1(v-if="options.display_join_bar") Join the show @ {{show_url}}
  slot.is-flex-grow-1

</template>

<script>
import Frame from '@/Frame';

export default {
  name: 'BackgroundVideo',
  extends: Frame,
  computed: {
    show_url() {
      let url = window.location.host+"/show/"+this.$store.state.show_id;
      return url;
    },
  }
} 
</script>

<style scoped>
.show_url {
  width: 100%;
  background-color: #222;
  color: white;
  font-size: 3em;
  text-align: center;
}
</style>

