let modules = [
  require("./Guest"),
  require("./BackgroundVideo"),
  require("./Join"),
  require("./Live"),
  require("./Teleprompter"),
];

export default Object.assign({}, ...modules.map(m=>({[m.default.name]: m.default})));

