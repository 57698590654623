<template lang="pug">
tr
</template>

<script>

import {url_for} from '@/config';
import axios from 'axios';
import ShowAdmin from './ShowAdmin';

export default {
  name: 'Show',

  props: {
    show: Object,
    fetch_shows: Function,
    on_select_show: Function,
  },

  data: ()=>({
  }),

  components: {
    ShowAdmin
  },
  
  mounted() {
  },

  methods: {
  },

  computed: {
  },
} 
</script>

<style lang="scss">
</style>
