<template lang="pug">
.home
  .fullscreen(v-if="this.$store.state.uid")
    FrameView
      SceneView(
        :payload="payload",
      )
  Teleport(to="body")
    ChangeNameModal
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '@/firebase_pirogi';

import SceneView from '@/views/SceneView';
import FrameView from '@/views/FrameView';
import ChangeNameModal from '@/components/ChangeNameModal';

export default {
  name: 'Home',

  props: {
    show_id: String
  },

  data: ()=>({
  }),

  components: {
    FrameView,
    SceneView,
    ChangeNameModal
  },
  
  created() {
    this.$store.commit('set_show_id', this.show_id);
    if (this.$store.state.uid) {
      this.$store.dispatch('bind_show');
      this.$store.dispatch('bind_local_player');
    } else {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          this.$store.dispatch('bind_show');
          this.$store.dispatch('bind_local_player');
        }
      });
    }
    //this.$store.dispatch('bind_players');
  },

  mounted() {
    document.title = "SHOW " + this.show_id;
  },

  methods: {
  },

  computed: {
    payload() {
      return this.$store.getters.payload;
    },

    uid() {
      return this.$store.state.uid;
    },
  },

} 
</script>

<style scoped>
.home, .fullscreen {
  width: 100%;
  height: 100%;
}
</style>
