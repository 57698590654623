import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

import '@firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCGoM-IWIOeDhzVeBbLv7mbheTGNXNXTP8",
  authDomain: "pirogi-b9144.firebaseapp.com",
  projectId: "pirogi-b9144",
  storageBucket: "pirogi-b9144.appspot.com",
  messagingSenderId: "608828382199",
  appId: "1:608828382199:web:995e1b9deb46cec061acf2",
  measurementId: "G-KBKJQY6LTM"
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);
export const db = app.firestore();
export const auth = app.auth();

if (process.env.NODE_ENV != 'production') {
  db.useEmulator(window.location.hostname, 8080);
} 

//export const analytics = getAnalytics(app);

