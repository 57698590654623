<template lang="pug">
.frame
  component(
    v-if="frame_name"
    :is="frame_name"
    :options="frame_options"
  )
    slot
  div(v-else style="{width: 100%; height: 100%;}")
    slot

</template>

<script>

import available_frames from '@/frames';
console.log("AVAILABLE_FRAMES", available_frames);

import { nanoid } from 'nanoid'
import { url_for } from '@/config';

export default {
  name: 'FrameView',

  props: {
  },

  components: {
    ...available_frames
  },
  
  computed: {
    frame_name() {
      return this.$store.getters.frame && this.$store.getters.frame.name;
    },
    frame_options() {
      return this.$store.getters.frame;
    }
  },
} 
</script>

<style lang="scss">
.frame {
  width: 100%;
  height: 100%;
}


</style>

