
<template lang="pug">
.frame
  video#background_video(playsinline autoplay muted loop)
    source(:src="options.background_video_url" type="video/mp4")
  Nav
  LiveStream
    YouTube#stream(:stream_id="options.stream_id")
  slot

</template>

<script>
import Frame from '@/Frame';
import YouTube from '@/components/YouTube';
import LiveStream from '@/components/LiveStream';


export default {
  name: 'Live',
  extends: Frame,

  props: {
  },

  data: ()=>({
  }),
  
  components: {
    LiveStream,
    YouTube
  },

} 
</script>
