<template lang="pug">
.modal.is-active(v-if="!player_name")
  .modal-background(@click="cancel_change_name")
  .modal-content
    .box
      p.is-4 Change your display name
      input.input(v-model="new_name" placeholder="")
      hr
      div 
        button.button.is-danger(@click="cancel_change_name") Cancel
        button.button.is-primary(@click="change_name") OK


</template>

<script>

export default {
  name: 'ChangeNameModal',

  props: {
  },

  data: ()=>({
    new_name: "",
  }),

  methods: {
    change_name() {
      this.$store.dispatch('change_player_name', this.new_name);
    },
  },

  computed: {
    player_name() {
      return this.$store.getters.player.name;
    },
  },
} 
</script>

<style lang="scss">
</style>

