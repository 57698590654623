<template lang="pug">
div
  .is-flex.is-flex-direction-row.is-align-items-flex-start
    .panel.pr-3
      .panel-heading Running Shows
      .panel-block
        table.table.is-striped.is-bordered.is-one-quarter
          thead
            tr
              th id
              th type
              th started
              th 
          tbody(v-for="(show, id) in shows")
            tr
              td: a(@click="select_show(id)") {{id}}
              td {{show.cls}}
              td 1000 days ago
              td
                a(@click="delete_show(id)") end
      
    .is-flex-grow-1(v-if="cur_show_id")
      ShowAdmin.pl-3(:id="cur_show_id")
</template>

<script>

import StartShow from '@/admin/StartShow';
import ShowAdmin from './ShowAdmin';
import {url_for} from '@/config';
import axios from 'axios';

export default {
  name: 'Shows',

  props: {
  },

  data: ()=>({
    shows: {},
    cur_show_id: null
  }),

  components: {
    StartShow,
    ShowAdmin
  },
  
  async created() {
    this.fetch_shows();
  },

  methods: {
    async fetch_shows() {
      let response = await axios.get(url_for("get_shows"));
      this.shows = await response.data;
    },

    select_show(show_id) {
      this.cur_show_id = show_id;
    },

    async delete_show(show_id) {
      await axios.post(url_for("delete_show"), {
        show_id
      });
      this.cur_show_id = null;
      this.fetch_shows();
    }
  },

  computed: {
  },
} 
</script>

<style lang="scss">
</style>
