<template lang="pug">
.scene.p-4
  .box.is-light.has-text-centered
    .title.is-2 {{payload.title}}
    .text {{payload.text}}
    input.input(type="text")
  .buttons
    button.button.is-fullwidth(
      v-for="button in payload.buttons"
      @click="publish({type: button.type})"
    ) {{ button.text }}
       

</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '@/firebase_pirogi';
import Scene from '@/scene';

export default {
  name: 'TextInput',
  extends: Scene,

  props: {
    payload: Object,
  },

  data: ()=>({
  }),
  
  components: {
  },

  mounted() {
  },

  methods: {
    onclick(val) {
      this.set_response(val);
    },
  },

  computed: {
  },
} 
</script>
