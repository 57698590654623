<template lang="pug">
div.h-100
  div(v-if="!available_shows") Loading
  div(v-else)
    .field
      .label Show Class
      .control
        .select
          select(v-model="show.cls_name" :disabled="!can_change_cls_name")
            option(v-for="(d, cls_name) in this.available_shows") {{cls_name}}
    .field
      .label Airtable Row
      .control
        .select(v-if="show.cls_name")
          select(v-model="show.row_id")
            option(v-for="row_id in this.selected_show_dict.row_ids") {{row_id}}
    .field
      .label Override show_id
      .control
        input.input(type="text" v-model="show.override_show_id")
    .field(v-if="show.cls_name")
      .label Attributes
      .control
        table.table
          thead
            tr
              th key
              th value
              th default
          tbody
            tr(v-for="_default, key in this.selected_show_dict.default_attributes")
              td {{key}}
              td
                input(
                  type="text"
                  v-model="show.attributes[key]"
                )
              td
                i {{ _default }}
    .field
      .control
        button.button.is-success(@click="on_button_click()" :disabled="!is_valid_show") {{button_label}}

</template>

<script>

import {url_for} from '@/config';
import axios from 'axios';
import _ from 'lodash';

export default {
  name: 'StartShow',

  props: {
    defaults: Object,
    can_change_cls_name: Boolean,
    button_label: String,
    on_success: Function
  },

  data: ()=>({
    show: {
      cls_name: null,
      row_id: null,
      override_show_id: null,
      attributes: {}
    }
  }),

  components: {
  },
  
  created() {
    // copy the defaults into the current representation of the show
    _.each(this.defaults, (value, key)=>{
      console.log(key, this.defaults[key]);
      this.show[key] = this.defaults[key];
    });

    // ensure that the default arguments are setup correctly
    _.each(this.selected_show_dict.default_attributes, (value, key)=>{
      this.show.attributes[key] ||= value;
    });
  },

  mounted() {
  },

  methods: {
    dismiss_started_show_modal() {
      this.$store.commit('set_started_show_id', null);
    },
    on_button_click() {
      this.show.attributes = this.combined_attributes;
      this.on_success(this.show);
    }
  },

  computed: {
    available_shows() {
      return this.$store.state.available_shows;
    },
    started_show_id() {
      return this.$store.state.started_show_id;
    },
    is_valid_show() {
      return true;
      //return this.cur_cls_name && this.cur_row_id;
    },
    selected_show_dict() {
      console.log("XXX", this.show, this.available_shows);
      return this.available_shows[this.show.cls_name]
    },
    combined_attributes() {
      let d = {};
      _.each(this.selected_show_dict.default_attributes, (value, key)=>{
        if (typeof value == "number") d[key] = Number(this.show.attributes[key] || value);
        else if (typeof value == "boolean") d[key] = (this.show.attributes[key] || value) == "true";
        else d[key] = value;
      });
      return d;
    }
  },
} 
</script>

<style lang="scss">
</style>

