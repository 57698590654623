let modules = [
  require('./Idle'),
  require('./Tabs'),
  require('./MultipleChoice'),
  require('./ButtonGrid'),
  require('./Leaderboard'),
  require('./Video'),
  require('./Simple'),
  require('./HostScript'),
  require('./Title'),
  require('./Correct'),
  require('./TextInput'),
  require('./SimpleAnswerSheet'),
  require('./Live'),
  require('./VideoEmbedTest'),
  require('./Countdown'),
  require('./ReadyButton'),
  require('./HorizontalBarChart'),
];

let scenes = Object.assign({}, ...modules.map(m=>({[m.default.name]: m.default})));
console.log("SCENES", scenes);

export default scenes;
