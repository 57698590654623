<template lang="pug">
.block.has-text-centered
  .title.is-2(v-html="title")
  .subtitle.is-3(v-html="subtitle")
  .text(v-html="text")
  
</template>

<script>
export default {
  name: 'TitleText',
  
  props: {
    title: String,
    subtitle: String,
    text: String
  },
  
}
</script>

<style lang="scss" scoped>

</style>
