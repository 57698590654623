<template lang="pug">
.scene
  div {{payload.text}}
</template>

<script>

export default {
  name: 'Scene',

  props: {
    payload: Object,
  },

  data: ()=>({
    loaded_at: null
  }),
  
  components: {
  },

  mounted() {
    this.loaded_at = Date.now();
    this.publish({
      type: "LOADED"
    });
  },

  methods: {
    publish(message) {
      if (this.$store) {
        this.$store.dispatch('publish', {show_id: this.payload.show_id, message});
      } else {
        console.log("dummy PUBLISH", message);
      }
    },
    set_response(val) {
      if (this.$store) {
        this.$store.dispatch('set_response', val);
      } else {
        console.log("dummy SET RESPONSE", val);
      }
    }
  },

  computed: {
    loaded_for() {
      return Date.now() - this.loaded_at;
    }
  },
} 
</script>

<style lang="scss">
.scene {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.scene > .box {
  background: black;
  color: white;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  padding: 10px;
}
</style>
