<template lang="pug">
div.flex-shrink-1
  progress.progress(
    v-if="payload && payload.countdown_duration"
    :value="norm"
    max="1.0",
  )
  
</template>

<script>
export default {
  name: 'CountdownBar',
  
  props: {
    payload: Object
  },

  data: ()=>({
    created_at: null,
    raf_id: null,
    norm: 0,
  }),

  mounted() {
    this.created_at = Date.now();
    this.interval_id = setInterval(this.animate, 0.05);
  },

  beforeUnmount() {
    clearInterval(this.interval_id);
  },

  methods: {
    animate() {
      let countdown_elapsed = this.payload.countdown_elapsed ?? 0;
      this.norm = (Date.now() - this.created_at + countdown_elapsed)/1000.0/this.payload.countdown_duration;
    }
  },

  
}
</script>

<style lang="scss" scoped>
  .progress {
    border-radius: 0px;
  }
</style>
