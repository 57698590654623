<template lang="pug">
.scene
  .hero.is-primary.has-text-centered
    .hero-body
      p.title Please Wait
  
</template>

<script>
import Scene from '@/scene';

export default {
  name: 'Idle',
  extends: Scene,

  props: {
  },

  data: ()=>({
  }),
  
  components: {
  },

  mounted() {
  },

  methods: {
  },

  computed: {
  },
} 
</script>

<style lang="scss" scoped>
</style>
