<template lang="pug">
.scene
  .tabs.has-background-black.is-fullwidth.is-large
    ul
      li(
        v-for="(tab_name, tab_idx) in tab_names"
        :class="{'is-active': tab_idx == cur_tab_idx}"
      )
       a(@click="on_select_tab(tab_idx)") {{ tab_name }}
  SceneView(
    :payload="cur_tab_payload",
    :key="cur_tab_idx"
  )

</template>

<script>
import { db } from '@/firebase_pirogi';
import Scene from '@/scene';

export default {
  name: 'Tabs',
  extends: Scene,

  props: {
    payload: Object,
  },

  data: ()=>({
    cur_tab_idx: null
  }),

  beforeCreate() {
    // we do this here to prevent a circular dependency when doing it in components
    this.$options.components.SceneView = require("@/views/SceneView").default;
  },
  
  methods: {
    on_select_tab(tab_idx) {
      this.cur_tab_idx = tab_idx;
    }
  },

  created() {
    this.cur_tab_idx = 0;
  },

  computed: {
    tab_names() {
      return this.payload.tabs.map(t=>t.tab_name);
    },
    cur_tab_payload() {
      return this.payload.tabs[this.cur_tab_idx];
    }
  },
} 
</script>

<style lang="scss" scoped>
scene { 
  width: 100%;
}
</style>

