<template lang="pug">
.frame
  video#background_video(playsinline autoplay muted loop)
    source(:src="options.background_video_url" type="video/mp4")
  .box.has-text-centered
    .title Join! {{ url }}
  slot

</template>

<script>
import Frame from '@/Frame';

export default {
  name: 'Join',
  extends: Frame,

  computed: {
    url() {
      return `${window.location.host}/show/${this.options.show_id}`;
    }
  },
} 
</script>

