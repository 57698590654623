<template lang="pug">
.scene
  .box.is-flex.is-flex-direction-column.is-flex-grow-1
    TitleText(:title="payload.title", :subtitle="payload.subtitle" :text="payload.text")
    .chart-parent.is-flex-grow-1
      svg.chart(:id="uid")
  
</template>

<script>
import Scene from '@/scene';
import * as d3 from "d3";
import palette from "google-palette";
import TitleText from '@/components/TitleText';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'HorizontalBarChart',
  extends: Scene,

  props: {
  },

  data: ()=>({
    uid: null,
    svg: null
  }),

  components: {
    TitleText
  },

  created() {
    this.uid = "chart-"+uuidv4();
  },

  mounted() {
    this.draw();
  },

  beforeUnmount() {
  },

  methods: {
    draw() {
      let chart_parent = this.$el.querySelector(".chart-parent");
      let w = chart_parent.offsetWidth;
      let h = chart_parent.offsetHeight;

      let margin = 10;

      this.svg = d3
        .select("#"+this.uid)
        .attr("width", w-2*margin)
        .attr("height", h-2*margin);


      let data = this.payload.data;
      data.reverse();

      let colors = palette('mpn65', data.length);
      data = data.map((d,i)=>({
        color: colors[i],
        label: `${d.label} • ${d.value}`,
        value: d.value,
      }))

      let x = d3.scaleLinear().range([0, w]);
      let y = d3.scaleBand().range([h-2*margin, 0]);

      let g = this.svg.append("g")
          .attr("transform", "translate(" + margin + "," + margin + ")");
      
      x.domain([0, d3.max(data, function(d) { return d.value; })]);
      y.domain(data.map(function(d) { return d.label; })).padding(0.1);

      g.append("g")
          .attr("class", "x axis")
          .attr("transform", "translate(0," + h + ")")
          .call(d3.axisBottom(x).ticks(5).tickFormat(function(d) { return parseInt(d); }).tickSizeInner([-h]));

      let bar = g.selectAll(".bar").data(data).enter();

      let rect = bar.append("rect")
      let text = bar.append("text");

      rect
        .attr("class", "bar")
        .attr("x", 0)
        .attr("height", y.bandwidth())
        .attr("y", function(d) { return y(d.label); })
        .attr("fill", function(d) { return "#"+d.color })
        .transition()
        .attr("width", function(d) { return x(d.value); })

      let font_size = 40;
      text
        .attr("class", "hbar_label")
        .attr("x", 10)
        .attr("y", (d)=>(y(d.label) + y.bandwidth()-10))
        .attr("font-size", (d)=>(Math.min(y.bandwidth(), 60)))
        .attr("fill", "white")
        .text(function(d) { return d.label });
    }
  },
  
  computed: {
  },
} 
</script>

<style lang="scss" scoped>
.scene {
}
.box {
}

</style>
