<template lang="pug">
.scene
  video(autoplay, ref="video", @ended="on_end()", v-if="cur_url", :key="cur_url" @click="on_end")
    source(:src="cur_url")
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '@/firebase_pirogi';
import Scene from '@/scene';
import { nextTick } from 'vue'


export default {
  name: 'Video',
  extends: Scene,

  data: ()=>({
    cur_url: null
  }),
  
  components: {
  },

  mounted() {
    this.play_next();
  },

  methods: {
    play_next() {
      let url = this.payload.urls.shift();
      this.play(url);
    },

    play(url) {
      this.cur_url = url;
      nextTick(()=>{this.$refs.video.play()});
    },

    on_end() {
      if (this.payload.urls.length >= 1) {
        let url = this.payload.urls.shift();
        this.play(url);
      } else {
        this.publish({
          type: "VIDEO_END"
        });
      }
    }
  },

  watch: {
    payload() {
      this.play_next();
    }
  },

  computed: {
  },
} 
</script>

<style>
  .scene > video {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
  }
</style>
