<template lang="pug">
.section
  .container
    div(v-if="amount > 0")
      .title.is-3 Purchase {{title}}
      div cost ${{amount/100}}
      div {{description}}
      #payment-request-button
    div(v-else) Please Wait
      
</template>

<script>
import { url_for } from '@/config';
import axios from 'axios';

export default {
  name: 'Purchase',

  props: {
    id: String
  },

  data: ()=> ({
    title: "",
    description: "",
    amount: 0,

    show_cls: null,
    row_id: null,
  }),

  components: {
  },
  
  async mounted() {
    let res = await axios.post(
      url_for("stripe_create_payment_intent"), {
        purchase_id: this.id
      }
    );
    let clientSecret = res.data.client_secret;
    this.title = res.data.title;
    this.description = res.data.description;
    this.amount = res.data.amount;
    this.show_cls = res.data.show_cls;
    this.row_id = res.data.row_id;

    var stripe = window.Stripe('pk_test_Sztq58bFjjlAYIQz3f267f8l', {
      apiVersion: "2016-07-06",
    });

    const paymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: this.title,
        amount: this.amount,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    const elements = stripe.elements();
    const prButton = elements.create('paymentRequestButton', {
      paymentRequest,
    });

    (async () => {
      // Check the availability of the Payment Request API first.
      const result = await paymentRequest.canMakePayment();
      if (result) {
        prButton.mount('#payment-request-button');
      } else {
        document.getElementById('payment-request-button').style.display = 'none';
      }
    })();

    paymentRequest.on('paymentmethod', async (ev) => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
        clientSecret,
        {payment_method: ev.paymentMethod.id},
        {handleActions: false}
      );

      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        ev.complete('fail');
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete('success');
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (paymentIntent.status === "requires_action") {
          // Let Stripe.js handle the rest of the payment flow.
          const {error} = await stripe.confirmCardPayment(clientSecret);
          if (error) {
            // The payment failed -- ask your customer for a new payment method.
          } else {
            // The payment has succeeded.
            this.start_show();
          }
        } else {
          // The payment has succeeded.
          this.start_show();
        }
      }
    });
  },

  computed: {
    started_show_id() {
      return this.$store.state.started_show_id;
    }
  },

  watch: {
    started_show_id(_started_show_id) {
      if (_started_show_id)
        this.$router.push({path: `/show/${_started_show_id}`});
    }
  },

  methods: {
    start_show() {
      let d = {
        cls_name: this.show_cls,
        row_id: this.row_id,
        started_by: this.$store.state.uid
      }
      this.$store.dispatch('start_show', d);
    }
  }
} 
</script>

