<template lang="pug">
.frame
  slot
</template>

<script>

import Nav from '@/components/Nav';
export default {
  name: 'Frame',

  props: {
    options: Object
  },

  components: {
    Nav
  },

  mounted() {
  },

  methods: {
  },

  computed: {
  },
} 
</script>

<style lang="scss">
.frame {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  padding: 0px;
}

#background_video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1000;
}

</style>
