
<template lang="pug">
.scene.p-4
  .box
    h1 this is a test
  .box(ref="stream_element" style="width: 600px; height: 400px;")

</template>

<script>
import Scene from '@/scene';

export default {
  name: 'VideoEmbedTest',
  extends: Scene,

  props: {
    payload: Object,
  },

  data: ()=>({
  }),
  
  components: {
  },

  mounted() {
    this.$store.commit('set_stream_element', this.$refs.stream_element);
  },

  methods: {
  },

  computed: {
  },
} 
</script>
