<template lang="pug">
.frame
  .columns
    .column
      slot
    .column
      div teleprompter

</template>

<script>
import Frame from '@/Frame';

export default {
  name: 'Teleprompter',
  extends: Frame,

  props: {
  },

  data: ()=>({
  }),
  
  components: {
  },

  mounted() {
  },

  methods: {
  },

  computed: {
  },
} 
</script>
