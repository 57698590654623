<template lang="pug">
nav.navbar.is-black
  .navbar-brand
    .navbar-item
      img.logo(src="@/assets/2bc.png")
    .navbar-item
      a(@click="reset_name"): b {{player_name}}
    .navbar-item
      span score: {{score}}
    .navbar-item
      ClickableQRModal(
        :url="show_url"
      )

</template>

<script>

import ClickableQRModal from './ClickableQRModal';

export default {
  name: 'Nav',

  props: {
  },

  data: ()=>({
    editing_name: false,
    new_name: "",
    qr_modal_visible: false
  }),

  components: {
    ClickableQRModal
  },
  
  async created() {
  },

  methods: {
    reset_name() {
      this.$store.dispatch('change_player_name', "");
    }
  },

  computed: {
    show_url() {
      return window.location.href;
    },

    player_name() {
      return this.$store.getters.player.name;
    },

    score() {
      return this.$store.state.local_player.score ?? 0;
    }

  },
} 
</script>

<style lang="scss">
  .logo {
    height: 48px;;
  }
</style>

