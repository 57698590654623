<template lang="pug">
.scene.p-4
  TitleText(:title="payload.title", :text="payload.text")
  .box
    img(:src="payload.img_url")
    MultiBoxTextInput(
      :num_characters="payload.num_characters"
      :highlighted_indices="payload.highlighted_indices"
    )

</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '@/firebase_pirogi';
import Scene from '@/scene';
import TitleText from '@/components/TitleText';
import MultiBoxTextInput from '@/components/MultiBoxTextInput';

export default {
  name: 'SimpleAnswerSheet',
  extends: Scene,

  props: {
    payload: Object,
  },

  data: ()=>({
  }),
  
  components: {
    TitleText,
    MultiBoxTextInput 
  },

  mounted() {
  },

  methods: {
  },

  computed: {
  },
} 
</script>
