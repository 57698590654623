<template lang="pug">
.admin.is-flex.is-flex-direction-column
  nav.navbar.is-black
    .navbar-brand
      .navbar-item
        img.logo(src="@/assets/2bc.png")
      .navbar-item
        b Pirogi Admin Interface
  .tabs.is-left
    ul
      li(
        v-for="tab in tabs" :class="{'is-active': cur_tab == tab}"
        @click="cur_tab=tab"
      ): a {{tab}}
  div.p-2.is-flex-grow-1
    StartShowShortcuts(v-if="cur_tab=='Start Show'")
    Shows(v-if="cur_tab=='Active Shows'")
    div(v-if="cur_tab=='Schedule Shows'")


</template>

<script>

import Show from '@/admin/Show';
import Shows from '@/admin/Shows';
import StartShow from '@/admin/StartShow';
import StartShowShortcuts from '@/admin/StartShowShortcuts';
import {url_for} from '@/config';
import axios from 'axios';

export default {
  name: 'TabletLanding',

  props: {
  },

  data: ()=>({
    tabs: [
      "Start Show",
      "Active Shows",
      "Schedule Shows"
    ],
    cur_tab: null
  }),

  components: {
    Show,
    Shows,
    StartShow,
    StartShowShortcuts,
  },
  
  async created() {
    this.cur_tab = this.tabs[0];
    this.fetch_shows();
  },

  methods: {
    async fetch_shows() {
      console.log(url_for("get_shows"));
      let response = await axios.get(url_for("get_shows"));
      this.shows = await response.data;
    },
  },

  computed: {
  },
} 
</script>

<style lang="scss">
</style>
