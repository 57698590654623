<template lang="pug">
div
  div endpoint {{endpoint}}
  a(:href='start_show_url') Start Show
  Purchase
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '@/firebase_pirogi';

import { url_for } from '@/config';

import Purchase from '@/views/Purchase';

export default {
  name: 'StartShow',

  props: {
    endpoint: String
  },

  components: {
    Purchase
  },

  data: ()=>({
  }),
  
  computed: {
    start_show_url() {
      let url = url_for(this.endpoint);
      return `${url}?uid=${this.$store.state.uid}`;
    }
  },
} 
</script>
