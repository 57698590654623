<template lang="pug">
div
  div
    table.table.is-bordered.is-striped
      thead
          th name
          th type
          th
          th
      tbody
        tr(v-for="[name, shortcut] in shortcuts")
          td: b: a(@click="set_cur_shortcut(name, shortcut)") {{name}}
          td  {{shortcut.cls_name}}
          td
            a(@click="edit_shortcut(name, shortcut)") edit
          td
            a(@click="delete_shortcut(name)") delete

    .dropdown(
      :class="{'is-active': show_new_dropdown}"
      v-click-outside="()=>{show_new_dropdown=false}"
    )
      .dropdown-trigger
        button.button(@click="()=>{show_new_dropdown=true}") New Shortcut
      .dropdown-menu
        .dropdown-content
          a.dropdown-item(
            v-for="show, cls_name in available_shows"
            @click="shortcut_to_create = {cls_name}"
          ) {{cls_name}}

  .modal.is-active(v-if="shortcut_to_create")
    .modal-background(@click="shortcut_to_create=null")
    .modal-card
      header.modal-card-head
        p.modal-card-title Create Show
      section.modal-card-body
        .field
          .label Show Name
          .control
            input.input(type="text" v-model="shortcut_to_create_name")
        StartShow(
          :defaults="shortcut_to_create",
          button_label="Create",
          :on_success="create_shortcut"
        )

  .modal.is-active(v-if="cur_shortcut")
    .modal-background(@click="cur_shortcut=null")
    .modal-card
      header.modal-card-head
        p.modal-card-title Start {{cur_shortcut_name}}
      section.modal-card-body
        StartShow(
          :defaults="cur_shortcut",
          button_label="Start"
          :on_success="start_show"
        )
</template>

<script>

import {url_for} from '@/config';
import axios from 'axios';
import StartShow from '@/admin/StartShow';

export default {
  name: 'StartShowShortcuts',

  props: {
  },

  data: ()=>({
    shortcuts: [],
    cur_shortcut: null,
    cur_shortcut_name: null,
    shortcut_to_create: null,
    shortcut_to_create_name: null,
    
    show_new_dropdown: false,
  }),

  components: {
    StartShow,
  },
  
  created() {
    this.$store.dispatch('fetch_available_shows');
    this.fetch_shortcuts();
  },

  methods: {
    async start_show(show) {
      this.cur_shortcut = null;
      let show_id = await this.$store.dispatch('start_show', show);
      console.log("STARTED", show_id);
      this.$router.push(`/admin/show/${show_id}`);
      
    },

    set_cur_shortcut(name, shortcut) {
      this.cur_shortcut_name = name;
      this.cur_shortcut = shortcut;
    },

    async create_shortcut(shortcut) {
      await axios.post(url_for("set_shortcut"), {
        name: this.shortcut_to_create_name,
        shortcut: shortcut
      });
      this.shortcut_to_create = null;
      await this.fetch_shortcuts();
    },

    async delete_shortcut(name) {
      let response = await axios.post(url_for("delete_shortcut"), { name });
      await this.fetch_shortcuts();
    },

    edit_shortcut(name, shortcut) {
      this.shortcut_to_create = shortcut;
      this.shortcut_to_create_name = name;
    },

    async fetch_shortcuts() {
      let response = await axios.get(url_for("get_shortcuts"));
      this.shortcuts = response.data;
    },

    show_dropdown(tf) {
      this.show_new_dropdown = tf;
    }
  },

  computed: {
    available_shows() {
      return this.$store.state.available_shows;
    },
  }
} 
</script>

<style lang="scss">
.shortcut {
  border: 2px solid black;
  padding: 2px;
  margin: 5px;
}
</style>

