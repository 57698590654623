<template lang="pug">
.home
  .activate-container(v-if="!button_pressed")
    button.button.activate-button.is-danger.is-rounded(@click="button_pressed = true") PRESS TO ACTIVATE
  .fullscreen(v-else v-if="this.$store.state.uid")
    FrameView
      SceneView(
        :payload="payload",
      )
</template>

<script>
import firebase from 'firebase/compat/app';

import Frame from '@/Frame';
import SceneView from '@/views/SceneView';
import FrameView from '@/views/FrameView';

export default {
  name: 'Home',

  props: {
    slot_id: String
  },

  data: ()=>({
    button_pressed: false
  }),

  components: {
    SceneView, FrameView
  },
  
  created() {
    this.$store.commit('set_slot_id', this.slot_id);
    if (this.payload && this.payload.show_id)
      this.$store.commit('set_show_id', this.payload.show_id);

    if (this.$store.state.uid) {
      this.$store.dispatch('bind_slot', this.slot_id);
    } else {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) this.$store.dispatch('bind_slot', this.slot_id);
      });
    }
  },

  mounted() {
    document.title = "SLOT " + this.slot_id;
  },

  methods: {
  },

  computed: {
    payload() {
      return this.$store.getters.payload;
    },

    show_id() {
      return this.payload?.show_id;
    }
  },

  watch: {
    show_id(new_show_id) {
      this.$store.commit("set_show_id", new_show_id);
      this.$store.dispatch("bind_show");
    
    }
  }

} 
</script>

<style scoped>
.home, .fullscreen {
  width: 100%;
  height: 100%;
}

.activate-container {
  background-color: black;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.activate-button {
  width: 50%;
  height: 50%;
  font-size: 3em;
}
</style>

