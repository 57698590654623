<template lang="pug">
div
  .panel
    .panel-heading {{show.id}}
    .panel-block
      table.table
        tr
          th URLs
        tr(v-for="(value, key) in this.show.urls")
          th {{key}}
          td
            a(:href="value") {{value}}
            ClickableQRModal(:url="full_url_for(value)")
        tr
          th Class
          td {{show.cls}}
        tr
          th Row ID
          td {{show.row_id}}
        tr
          th State
          td
            .select
              select(@change="on_change_state")
                option(v-for="state in this.show.states") {{state}}
                
  .panel
    .panel-heading Attributes
    .panel-block
      table.table
        tr(v-for="value,key in plain_attributes")
          th {{key}}
          td {{value}}
  .panel
    .panel-heading Log
    .panel-block
      div
        p(style="font-family: monospace" v-for="log in logs") {{log}}
</template>

<script>

import {url_for} from '@/config';
import axios from 'axios';

import firebase from 'firebase/compat/app';
import { db } from '@/firebase_pirogi';

import ClickableQRModal from '@/components/ClickableQRModal';

export default {
  name: 'ShowAdmin',

  props: {
    id: String,
  },

  data:()=>({
    logs: [],
  }),

  components: {
    ClickableQRModal
  },
  
  created() {
    this.$store.commit('set_show_id', this.id);
    if (this.$store.state.uid) {
      this.$store.dispatch('bind_show');
    } else {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) this.$store.dispatch('bind_show');
      });
    }
  },

  mounted() {
    db.collection("shows").doc(this.id).collection("log").onSnapshot(qs=>{
      console.log("here");
      let logs = [];
      qs.forEach(doc=>{logs.push(doc.data().line);})
      this.logs = logs;
    })
    
  },


  methods: {
    on_change_state(ev) {
      let next_state = ev.target.value;
      console.log("NEW STATE", next_state);
    },
    
    is_plain_value(val) {
    },

    full_url_for(path) {
      return window.location.origin + path;
    }
  },

  computed: {
    show() {
      return this.$store.state.show
    },
    plain_attributes() {
      if (!this.show.attributes) return {}
      let d = {};
      Object.entries(this.show.attributes).forEach(entry=>{
        let [key, value] = entry;
        if (typeof value == "string") d[key] = value;
        if (typeof value == "number") d[key] = value;
        if (typeof value == "boolean") d[key] = value;
      });
      return d;
    }
  },
} 
</script>

<style lang="scss">
</style>
