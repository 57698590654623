<template lang="pug">
.scene_view.is-flex.is-flex-direction-column
  CountdownBar.countdown(
    v-if="payload"
    :payload="payload"
    :key="payload.start_at"
  )
  component(
    :is="scene"
    v-if="payload"
    :payload="payload"
    :key="payload.key"
  )
  #state.tag.is-info(
    v-if="is_development"
  ) {{state}}
</template>

<script>

import Scene from '@/scene';
import scenes from '@/scenes';
import CountdownBar from '@/components/CountdownBar';

import { nanoid } from 'nanoid'
import { url_for } from '@/config';

export default {
  name: 'SceneView',

  props: {
    payload: Object,
  },

  data: ()=>({
    stylesheets: [
      "https://fonts.googleapis.com/css?family=Poppins"
    ]
  }),

  components: {
    CountdownBar,
    ...scenes
  },
  
  mounted() {
    let links = document.querySelectorAll("link");
    let existing_hrefs = Array.from(links).map(link=>link.getAttribute("href"));
    
    this.stylesheets.forEach(href=>{
      if (!existing_hrefs.includes(href)) {
        var head = document.head;
        var link = document.createElement("link");

        link.type = "text/css";
        link.rel = "stylesheet";
        let url = new URL(href);
        url.searchParams.append("nocache", nanoid());
        link.href = url.toString();

        head.appendChild(link);
      }
    });
  },

  methods: {
  },

  computed: {
    scene() {
      return this.payload.name;
    },

    state() {
      if (this.$store?.state.show?.state_stack)
        return this.$store.state.show.state_stack.join(" > ") +" "+ process.env.VUE_APP_PIROGI_VERSION;
      else
        return "NONE";
    },
    
    is_development() {
      return true;
      //return process.env.NODE_ENV === 'development';
    }
  },
} 
</script>

<style lang="scss">
.scene_view {
  width: 100%;
  height: 100%;
}

.scene {
  flex-grow: 1;
  //position: relative;
  //left: 0px;
  //top: 0px;
  //width: 100%;
  //height: 100%;
}

#state {
  position: absolute;
  right: 2px;
  bottom: 2px;
  font-size: 10px;
}

.countdown {
}

</style>
