<template lang="pug">
.scene
  .box
    .subtitle.has-text-centered {{payload.prompt}}
    button.button.is-fullwidth.is-outlined.is-inverted(
      :class="[ clicked ? 'is-success' : 'is-primary' ]"
      @click="onclick"
    ) {{payload.button_label}}
</template>

<script>
import Scene from '@/scene';

export default {
  name: 'ReadyButton',
  extends: Scene,

  data: ()=>({
    clicked: false
  }),
  
  mounted() {
  },

  methods: {
    onclick(el) {
      this.clicked = true;
      this.publish({
        type: "READY"
      });
    },
  },

  computed: {
  },
} 
</script>

<style lang="scss" scoped>
</style>
