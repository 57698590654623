<template lang="pug">
.guest.frame.is-flex.is-flex-direction-column
  video#background_video(playsinline autoplay muted loop)
    source(:src="options.background_video.url" type="video/mp4")
  Nav
  slot.is-flex-grow-1

</template>

<script>
import Frame from '@/Frame';

export default {
  name: 'Guest',
  extends: Frame,

  props: {
  },

  data: ()=>({
  }),
  
  components: {
  },

  mounted() {
  },

  methods: {
  },

  computed: {
  },
} 
</script>

