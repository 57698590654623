<template lang="pug">
teleport(to="body")
  slot
  
</template>

<script>
export default {
  // this is a LiveStream that relocated itself to whatever the store says the `stream_element` is
  name: 'LiveStream',
  
  props: {
    stream_id: String,
  },

  methods: {
    get_coords(elem) { 
      // get absolute coordinates of element
      // https://stackoverflow.com/questions/5598743/finding-elements-position-relative-to-the-document
      var box = elem.getBoundingClientRect();

      var body = document.body;
      var docEl = document.documentElement;

      var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

      var clientTop = docEl.clientTop || body.clientTop || 0;
      var clientLeft = docEl.clientLeft || body.clientLeft || 0;

      var top  = box.top +  scrollTop - clientTop;
      var left = box.left + scrollLeft - clientLeft;

      return {
        top: Math.round(top),
        left: Math.round(left),
        width: box.width,
        height: box.height,
      };
    }
  },

  computed: {
    stream_element() {
      return this.$store.state.stream_element
    }
  },

  watch: {
    stream_element(stream_element) {
      let coords = this.get_coords(stream_element);
      let elem = window.document.getElementById("stream"); // this is a hack!
      console.log(elem);
      elem.style.position = "absolute";
      elem.style.left = coords.left+"px";
      elem.style.top = coords.top+"px";
      elem.style.width = coords.width+"px";
      elem.style.height = coords.height+"px";
    }
  }
  
}
</script>

<style lang="scss" scoped>

</style>
