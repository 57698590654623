<template lang="pug">
.scene
  .box
    nav.panel
      p.panel-heading Top Scores
    table.table.is-striped.is-fullwidth
      thead
        tr
          th Place
          th Player
          th Score
      tbody
        tr.is-fullwidth.is-large(
          v-for="player in top_leaderboard"
          :key="player.uid"
          :class="{ 'is-selected': player.uid == uid }"
        )
          td {{ player.place }}
          td {{ player.name }}
          td {{ player.score }}

        tr(v-if="me.place > payload.max_place + 1")
          td …
        tr.is-selected(v-if="me.place > payload.max_place")
          td {{ me.place }}
          td {{ me.name}}
          td {{ me.score }}

        
</template>

<script>
import Scene from '@/scene';

export default {
  name: 'Leaderboard',
  extends: Scene,

  data: ()=>({
  }),
  
  components: {
  },

  methods: {
  },

  computed: {
    top_leaderboard() {
      return this.payload.leaderboard.filter(p => p.place <= this.payload.max_place);
    },

    me() {
      return this.payload.leaderboard.find(p => p.uid == this.uid);
    },

    uid() {
      if (this.payload.my_uid)
        return this.payload.my_uid;
      return this.$store?.state.uid;
    },
  },
} 
</script>

<style lang="scss" scoped>
</style>
