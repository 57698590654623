import { createStore } from 'vuex'
import { db } from '@/firebase_pirogi';

import { vuexfireMutations, firestoreAction } from 'vuexfire'

import axios from 'axios';
import { url_for } from '@/config';

function get_random_name() {
  let colors = ["Red", "Blue", "Green", "Yellow", "Purple", "Pink", "Orange", "Gold", "Navy blue", "Sky blue", "Lime green", "Teal", "Indigo", "Magenta", "Violet", "Salmon", "Crimson", "Lavender", "Plum", "Blue violet", "Cyan", "Maroon"];
  let animals = ["Ant", "Antelope", "Baboon", "Bat", "Beagle", "Bear", "Bird", "Butterfly", "Cat", "Caterpillar", "Chicken", "Cow", "Dog", "Dolphin", "Donkey", "Eagle", "Fish", "Fly", "Fox", "Frog", "Goose", "Gopher", "Gorilla", "Honey Bee", "Horse", "Iguana", "Jackal", "Jaguar", "Jellyfish", "Kangaroo", "Kiwi", "Koala", "Lemur", "Leopard", "Scorpion", "Snake", "Swan", "Turkey", "Zebra"];


  let color = colors[Math.floor(Math.random() * colors.length)];
  let animal = animals[Math.floor(Math.random() * animals.length)];

  return color + " " + animal;
}

export default createStore({
  state: {
    show: {},
    players: [],
    show_id: null,
    uid: null,
    started_show_id: null,
    global_player: {},
    local_player: {},

    slot_id: null,
    slot: {},

    stream_element: null, // DOM element where we should teleport a live stream element

    available_shows: null
  },

  mutations: {
    set_uid(state, uid) {
      state.uid = uid;
    },
    set_show_id(state, show_id) {
      state.show_id = show_id;
    },
    set_slot_id(state, slot_id) {
      state.slot_id = slot_id;
    },

    set_started_show_id(state, started_show_id) {
      state.started_show_id = started_show_id
    },

    set_player_name(state, player_name) {
      state.player_name = player_name;
    },

    set_stream_element(state, stream_element) {
      state.stream_element = stream_element;
    },

    set_available_shows(state, available_shows) {
      state.available_shows = available_shows;
    },

    ...vuexfireMutations,
  },

  actions: {
    login: ({commit, dispatch, state}, uid)=> {
      commit('set_uid', uid);
      dispatch('bind_global_player');
    },

    bind_global_player: async ({commit, state})=> {
      let global_player_doc = db.collection("players").doc(state.uid);
      let snapshot = await global_player_doc.get();
      if (!snapshot.exists) {
        await global_player_doc.set({
        });
      }

      firestoreAction(({ bindFirestoreRef }) => {
        return bindFirestoreRef(
          'global_player',
          global_player_doc,
          { wait: true }
        )
      })({commit, state});
    },

    bind_local_player: async ({commit, state, dispatch})=> {
      // get existing name

      let local_player_doc = db.collection('shows').doc(state.show_id).collection("players").doc(state.uid);
      let snapshot = await local_player_doc.get();
      let d = {
        "joined": Date.now(),
      };
      if (state.slot_id)
        d.slot_id = state.slot_id;

      await local_player_doc.set(d, {merge: true});

      await firestoreAction(({ bindFirestoreRef }) => {
        return bindFirestoreRef(
          'local_player',
          local_player_doc,
          { wait: true }
        )
      })({commit, state});

      let gss = await db.collection('players').doc(state.uid).get();
      let name = gss.data().name;
      if (name) 
        local_player_doc.update({name});

      dispatch('update_show_uid_to_player_name');
    },

    change_player_name: ({state, commit, dispatch}, player_name)=>{
      let global_player_doc = db.collection("players").doc(state.uid);
      let local_player_doc = db.collection("shows").doc(state.show_id).collection("players").doc(state.uid);
      global_player_doc.update({name: player_name});
      local_player_doc.update({name: player_name});
      dispatch('update_show_uid_to_player_name', player_name);
    },

    bind_show: async ({commit, state, dispatch})=> {
      await firestoreAction(({ bindFirestoreRef }) => {
        return bindFirestoreRef('show', db.collection('shows').doc(state.show_id))
      })({commit, state});
    },

    bind_players: async ({commit, state})=> {
      firestoreAction(({ bindFirestoreRef }) => {
        return bindFirestoreRef(
          'players',
          db.collection('shows').doc(state.show_id).collection("players"),
          { wait: true }
        )
      })({commit, state});
    },

    bindShows: ({commit, state})=> {
      firestoreAction(({ bindFirestoreRef }) => {
        return bindFirestoreRef('shows', db.collection('shows'))
      })({commit, state});
    },

    start_show: async ({commit, state}, d) => {
      console.log("STARTING SHOW", d);
      let response = await axios.post(url_for("start_show"), d);
      
      commit('set_started_show_id', response.data.show_id);
      return response.data.show_id;
    },

    publish: async ({commit, state}, {show_id, message}) => {
      if (!show_id) show_id = state.show_id;
      let d = {
        show_id: show_id,
        uid: state.uid,
        message: message
      };
      if (state.slot_id)
        d.slot_id = state.slot_id;
      console.log("PUBLISH", d);
      axios.post(url_for("publish"), d);
    },

    bind_slot: ({commit, state, dispatch}, slot_id)=> {
      firestoreAction(({ bindFirestoreRef }) => {
        return bindFirestoreRef(
          'slot',
          db.collection('slots').doc(slot_id),
          { wait: true }
        )
      })({commit, state});
    },

    set_response: async({commit, state, getters}, val) => {
      if (!state.show_id) {
        console.log("can't send response when there's no show_id (are you a slot?)");
        return;
      }
      let state_invocation_id = state.show.state_invocation_id;

      if (!state_invocation_id) {
        console.log("tried to set response with no state_invocation_id!");
        return;
      }

      val.state_invocation_id = state_invocation_id;
      val.player_name = getters.player.name;
      console.log("BEFORE SET RESPONSE");
      await db.collection("shows").doc(state.show_id).collection("responses").doc(state.uid).set(val);
      console.log("AFTER SET RESPONSE");
    },

    update_player_key: async({state, getters}, {key, value})=>{
      let player_doc = db.collection("shows").doc(state.show_id).collection("players").doc(state.uid);
      await player_doc.update({[key]: value});
    },

    update_show_uid_to_player_name: async ({state, getters}, player_name) => {
      if (!player_name)
        player_name = getters.player.name;
      let d = {};
      d[`uid_to_player_name.${state.uid}`] = player_name || "?";
      db.collection("shows").doc(state.show_id).update(d);
    },

    fetch_available_shows: async ({commit})=>{
      console.log("HERE FA");
      let response = await axios.get(url_for("get_available_shows"));
      commit('set_available_shows', response.data);
    }
  },

  modules: {
  },

  getters: {
    payload: (state) => {
      try {
        if (state.slot && 'payload' in state.slot)
          return state.slot.payload;
        else if (state.local_player && 'payload' in state.local_player)
          return state.local_player.payload
        else
          return state.show.payload;
      } catch (error) {
        console.log("no valid payload!");
        return null;
      }
    },

    frame: (state) => {
      try {
        if ('frame' in state.slot)
          return state.slot.frame
        else if ('frame' in state.local_player)
          return state.local_player.frame;
        else if ('frame' in state.show)
          return state.show.frame;
        else
          return null
      } catch (error) {
        console.log("no valid frame!");
        return null;
      }
    },

    player: (state) => {
      return Object.assign({}, state.global_player, state.local_player);
    },
  }
})
