<template lang="pug">
.scene.is-flex.is-align-items-stretch
  .box.is-flex-grow-2.is-flex.is-flex-direction-column
    TitleText.is-flex-shrink-1(:title="payload.question", :text="payload.title")
    
    .grid-container.is-flex-grow-1(:style="grid_layout")
      .grid-item(
        v-for="button in payload.buttons"
        :class="{ 'is-selected':  this.selected_buttons.has(button) }",
        :style="{ 'border-color': button.border ? button.border : 'gray' }"
        @click="onselect(button)"
      )
        .number-label {{button.background_label}}
        .quad-button.has-text-centered(
        ) {{button.text}}
        .subtext {{button.subtext}}
</template>

<script>
import Scene from '@/scene';
import textFit from 'textfit';
import TitleText from '@/components/TitleText';

export default {
  name: 'ButtonGrid',
  extends: Scene,

  data: ()=>({
    selected_buttons: new Set()
  }),
  
  components: {
    TitleText
  },

  mounted() {
    this.autosize();
  },
  updated() {
    this.autosize();
  },
  computed: {
    grid_layout() {
      let columns = this.payload.columns || 1;
      let rows = Math.ceil(this.payload.buttons.length / columns);
      let columns_text = "";
      let rows_text = "";


      for (let c=0; c<columns; c++) {
        columns_text += "1fr ";
      }
      for (let r=0; r<=rows; r++) {
        rows_text += "1fr ";
      }

      console.log(rows, rows_text);
      
      return {
        'grid-template-columns': columns_text,
        'grid-template-rows': rows_text,
      }
    }
  },
  methods: {
    autosize() {
      textFit(this.$el.querySelectorAll(".quad-button"), {alignHoriz: true, alignVert: true});
      textFit(this.$el.querySelectorAll(".number-label"), {alignHoriz: true, alignVert: true, maxFontSize: 300});
      textFit(this.$el.querySelectorAll(".subtext"), {alignHoriz: false, alignVert: false, maxFontSize: 300});
    },

    async onselect(button) {
      if (this.selected_buttons.has(button)) {
        this.selected_buttons.delete(button);
      } else {
        let max_selections = this.payload.max_selections || 1;
        if (max_selections == 1) {
          // special case: if there's only one then we just switch to the new one
          this.selected_buttons.clear();
        }
        if (this.selected_buttons.size < max_selections) {
          this.selected_buttons.add(button);
        }
      }

      let data = {
        buttons: [...this.selected_buttons],
        loaded_for: this.loaded_for
      };
      console.log("data is", data);
      if (this.payload.store_key) {
        this.$store.dispatch('update_player_key', {
          key: this.payload.store_key,
          value: data
        });
      }
      this.set_response(data);
    },
  }
} 

</script>

<style lang="scss" scoped>

.scene {
}

.grid-container {
  display: grid;
  gap: 10px 10px;
}

.grid-item {
  position: relative;
  background-color: black;
  color: white;
  border-radius: 20px;
  padding: 10px;
  border: 5px solid gray;
}

.quad-button {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.number-label {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: rgba(0,0,0,0);
  color: rgb(50,50,50);
}

.subtext {
  position: absolute;
  bottom: 5px;
  height: 40px;
  width: 100%;
}

.is-selected {
  border-color: yellow !important;
  color: yellow;
}


</style>
