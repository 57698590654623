<template lang="pug">
div
  figure.image.is-16by9
    iframe#youtube-iframe.has-ratio(
      :src="`https://www.youtube.com/embed/${stream_id}?enablejsapi=1&playsinline=1`",
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture", allowfullscreen
      ref="youtube_iframe"
    )
  
</template>

<script>
export default {
  name: 'YouTube',
  
  props: {
    stream_id: String,
  },

  mounted() {
    console.log("YouTube mounted");
  },

  beforeUpdate() {
    console.log("YouTube beforeUpdate");
  },
  
}
</script>

<style lang="scss" scoped>

</style>
