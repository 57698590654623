<template lang="pug">
.scene
  .box
    TitleText(:text="payload.script")
    .buttons
      button.button.is-fullwidth.is-info(
        @click="publish({type: 'DONE'})"
      ) DONE
       

</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '@/firebase_pirogi';
import Scene from '@/scene';
import TitleText from '@/components/TitleText';

export default {
  name: 'HostScript',
  extends: Scene,

  props: {
    payload: Object,
  },

  data: ()=>({
  }),
  
  components: {
    TitleText
  },

  mounted() {
  },

  methods: {
  },

  computed: {
  },
} 
</script>
