<template lang="pug">
.scene
  .box
    .columns
      .column.has-text-centered
        .title {{remaining_text}}
        .subtitle {{payload.subtitle}}
        .buttons
          button.button.is-primary.is-outlined.is-inverted.is-fullwidth(v-for="b in payload.add_buttons" v-if="can_extend", @click="add(b.seconds)") {{b.label}}
          button.button.is-primary.is-outlined.is-inverted.is-fullwidth(v-if="payload.continue_button" @click="_continue()"): b {{payload.continue_button}}

        .block {{payload.text}}

  
</template>

<script>
import Scene from '@/scene';

export default {
  name: 'Countdown',
  extends: Scene,

  props: {
  },

  data: ()=>({
    end_at: 0,
    remaining_seconds: 999,
    remaining_text: "00:00",
    interval_id: null
  }),
  
  components: {
  },

  mounted() {
    this.end_at = Date.now() + this.payload.seconds * 1000;
    this.interval_id = setInterval(()=>{
      this.update_remaining();
    }, 100);
  },

  beforeUnmount() {
    clearInterval(this.interval_id);
  },

  methods: {
    update_remaining() {
      let ms = this.end_at - Date.now();
      if (ms <= 0) {
        ms = 0;
        this.publish({
          type: "CONTINUE",
        });
      }

      let minutes = Math.floor(ms/1000/60);
      let seconds = Math.floor(ms/1000 - 60*minutes);

      this.remaining_seconds = ms/1000;
      this.remaining_text = minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");

    },

    add(seconds) {
      console.log("Add", seconds);
      this.publish({
        type: "ADD_SECONDS",
        seconds: this.remaining_seconds + seconds
      });
    },

    _continue() {
      this.publish({
        type: "CONTINUE",
      });
    }
  },

  computed: {
    can_extend() {
      return this.remaining_seconds < 60
    }
  },
} 
</script>

<style lang="scss" scoped>
</style>
