import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

require('@/assets/style.scss');

import { auth } from "@/firebase_pirogi";

auth.onAuthStateChanged((user) => {
  if (user) {
    let uid = user.uid;
    store.dispatch('login', uid);
  } else {
    store.commit('set_uid', null);
  }
});

auth.signInAnonymously()
  .then(() => {
    console.log("signed in");
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
  });


import vClickOutside from "click-outside-vue3"

const app = createApp(App);
app.use(store);
app.use(router);
app.use(vClickOutside);
app.mount('#app')
