function url_for(endpoint) {
  if (process.env.NODE_ENV == 'production') {
    return `https://us-central1-pirogi-b9144.cloudfunctions.net/${endpoint}`;
  } else {
    return `http://localhost:5001/pirogi-b9144/us-central1/${endpoint}`;
  }
  
}

exports.url_for = url_for;

