<template lang="pug">
div
  // v-for is 1 indexed!
  input(
    v-for="i in num_characters" 
    type="text"
    maxlength="1"
    @keyup="refocus($event)"
    :class="{highlighted: this.highlighted_indices.includes(i-1)}"
  )
  
</template>

<script>
export default {
  name: 'MultiBoxTextInput',
  
  props: {
    num_characters: Number,
    highlighted_indices: Array
  },
  
  methods: {
    refocus($event) {
      let next = $event.target.nextSibling;
      console.log(next.tagName);
      if (next.tagName == "INPUT")
        next.focus();
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  font-size: 2em;
  text-transform: uppercase;
  width: 1.5em;
  text-align: center;
  border: 4px solid black;
  margin-right: 4px;
}

input.highlighted {
  border: 4px solid red;
}

</style>
