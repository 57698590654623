import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import StartShow from '../views/StartShow.vue'
import Shows from '../admin/Shows.vue'
import ShowAdmin from '../admin/ShowAdmin.vue'
import TabletLanding from '../admin/TabletLanding.vue'
import Purchase from '@/views/Purchase';
import Slot from '@/views/Slot';

const routes = [
  {
    path: '/show/:show_id',
    name: 'Home',
    component: Home,
    props: true
  },
  {
    path: '/show/:show_id/:slot_id',
    redirect: from=>({
      path: `/slot/${from.params.show_id}-${from.params.slot_id}`
    })
  },
  {
    path: '/slot/:slot_id',
    name: 'Slot',
    component: Slot,
    props: true
  },
  {
    path: '/start_show/:endpoint',
    name: 'StartShow',
    component: StartShow,
    props: true
  },

  {
    path: '/purchase/:id',
    name: 'Purchase',
    component: Purchase,
    props: true
  },

  {
    path: '/admin/shows',
    name: 'Shows',
    component: Shows,
  },
  {
    path: '/admin/show/:id',
    name: 'ShowAdmin',
    component: ShowAdmin,
    props: true
  },
  {
    path: '/admin',
    name: 'TabletLanding',
    component: TabletLanding,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
